export const menuMixin = {
  data() {
    return {};
  },
  created: function() {},
  methods: {
    hasActiveChildren(match) {
      if (Array.isArray(match)){
        let tmpCheck = false;
        match.forEach(page => {
          if (
            // tmpCheck == false &&
            // this.$route["path"].indexOf("/" + page + "/") !== -1 //entire URL
            tmpCheck == false &&
            this.$route["path"].indexOf("/" + page + "/") == 0 //start of the URL
          ) {
            tmpCheck = true;
          }
        });
        return tmpCheck;
      } else {
        // console.log(match);
        // return this.$route["path"].indexOf("/" + match + "/") !== -1; //entire URL
        return this.$route["path"].indexOf("/" + match + "/") == 0; //start of the URL
      }
    },
    bullet: function() {
      // get the default bullet
      if (!this.navitem.bullet) {
        this.navitem.bullet = "dot";
      }
      // bullet class name
      return `menu-bullet-${this.navitem.bullet}`;
    },

    /**
     * Get current menu link path
     * @returns {{}|{path: string}}
     */
    link: function() {
      // check if no link in this menu item
      if (!this.navitem.page) {
        return {};
      }
      // pass the link prepend with slash
      return { path: `/${this.navitem.page}` };
    }
  }
};
