<template>
  <li class="menu-section">
    <h4 class="menu-text">
      {{ section.translate ? $t(section.translate) : section.title }}
    </h4>
    <i class="menu-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
export default {
  name: "KTMenuSection",
  components: {},
  props: {
    section: Object
  }
};
</script>
