<template>
  <li class="menu-item">
    <h3 class="menu-heading menu-toggle">
      <i class="menu-bullet menu-bullet-dot"><span></span></i>
      <span class="menu-text">
        <strong>{{
          submenuitem.section.translate
            ? $t(submenuitem.section.translate)
            : submenuitem.section.title
        }}</strong>
      </span>
      <i class="menu-arrow"></i>
    </h3>
    <ul class="menu-inner">
      <template v-for="(navitem, i) in submenuitem.items">
        <KTMenuItemText
          v-bind:navitem="navitem"
          v-permission="navitem.permissions"
          :key="'asdads_' + i"
        ></KTMenuItemText>
      </template>
    </ul>
  </li>
</template>

<script>
import KTMenuItemText from "@/view/layout/header/components/MenuItemText.vue";

export default {
  name: "KTMenuSection",
  components: {
    KTMenuItemText
  },
  props: {
    submenuitem: Object
  },
  methods: {},
  computed: {}
};
</script>
