var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.navitem.permissions),expression:"navitem.permissions"}],attrs:{"to":_vm.link()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active'
    ],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[(_vm.navitem.icon)?_c('i',{staticClass:"menu-icon",class:_vm.navitem.icon}):_c('i',{staticClass:"menu-bullet",class:_vm.bullet()},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.navitem.translate ? _vm.$t(_vm.navitem.translate) : _vm.navitem.title)+" ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }